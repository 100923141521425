/* draft editor */
.rdw-editor-wrapper {
  flex: 1;
}

.rdw-editor-toolbar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  margin: 0 8px;
}

.rdw-editor-main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0;
  border-radius: 0;
  margin: 0 8px;
  padding: 12px 4px;
}

.rdw-editor-main * {
  margin: 0!important;
  padding: 0!important;
}
